import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" 
          transform="translate(0.000000,990.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M4930 8974 c-625 -53 -1236 -246 -1765 -559 -77 -45 -305 -193 -315
-205 -3 -3 -50 -39 -105 -80 -55 -41 -102 -77 -105 -80 -3 -3 -39 -34 -80 -70
-117 -100 -262 -244 -374 -370 -101 -113 -274 -334 -311 -397 -11 -18 -23 -33
-27 -33 -4 0 -8 -5 -8 -10 0 -6 -18 -37 -40 -69 -128 -188 -301 -545 -391
-811 -222 -650 -272 -1310 -148 -1975 130 -700 477 -1390 964 -1915 102 -110
256 -259 345 -334 47 -39 87 -73 90 -76 7 -8 195 -149 255 -192 172 -122 506
-307 701 -388 43 -18 97 -41 119 -51 77 -35 405 -140 547 -174 408 -98 832
-137 1228 -115 180 10 374 30 475 49 277 54 526 117 675 171 47 18 99 36 115
42 17 5 44 16 60 23 17 7 66 28 110 45 113 45 349 165 485 247 63 38 126 75
140 83 14 8 27 18 30 21 3 3 46 33 95 67 223 151 559 454 736 662 35 41 67 77
70 80 3 3 43 54 88 113 370 489 616 1035 730 1622 44 226 61 396 68 661 21
830 -232 1634 -737 2344 -156 219 -419 503 -645 696 -169 145 -428 330 -580
415 -33 18 -62 36 -65 39 -9 11 -269 141 -372 186 -531 233 -1125 356 -1708
353 -107 -1 -265 -7 -350 -15z m773 -528 c139 -16 395 -64 497 -93 284 -82
495 -165 710 -277 702 -366 1280 -993 1567 -1701 167 -411 240 -759 250 -1195
7 -339 -14 -564 -83 -848 -160 -666 -490 -1227 -996 -1697 -109 -101 -132
-121 -245 -206 -311 -237 -649 -415 -1008 -532 -327 -106 -560 -149 -912 -169
-553 -31 -1100 70 -1605 295 -718 320 -1302 876 -1660 1582 -62 123 -128 278
-171 405 -34 100 -42 130 -87 297 -27 101 -57 275 -75 428 -17 151 -20 521 -5
676 25 246 94 573 160 749 12 30 28 75 37 100 110 319 334 699 589 1000 135
159 383 398 487 469 12 8 24 18 27 22 12 16 196 142 305 209 444 271 959 444
1460 489 66 6 134 13 150 15 65 9 478 -3 608 -18z"/>
<path d="M5010 8324 c-548 -60 -961 -192 -1402 -447 -591 -343 -1083 -899
-1351 -1528 -248 -581 -318 -1221 -202 -1844 84 -454 268 -882 549 -1279 435
-613 1111 -1073 1862 -1266 441 -114 928 -134 1389 -60 532 87 1072 325 1505
664 125 98 322 286 430 410 52 60 100 114 105 120 26 29 107 140 161 221 355
529 544 1150 544 1789 0 233 -37 525 -95 756 -124 491 -325 886 -662 1299 -76
94 -349 360 -453 442 -484 383 -982 600 -1610 701 -101 16 -183 21 -430 23
-168 2 -321 1 -340 -1z m-565 -1519 c45 -18 93 -63 116 -106 17 -33 20 -67 25
-227 3 -103 2 -236 -4 -295 -8 -95 -13 -113 -38 -150 -46 -65 -109 -92 -215
-92 -132 0 -210 46 -245 143 -20 57 -19 545 1 603 26 72 87 122 167 138 51 11
151 3 193 -14z m-1035 -85 l0 -90 -65 0 -65 0 0 -340 0 -340 -115 0 -115 0 0
340 0 340 -65 0 -65 0 0 90 0 90 245 0 245 0 0 -90z m261 86 c2 -2 2 -29 1
-60 l-4 -56 -109 0 -109 0 0 60 0 60 108 0 c60 0 111 -2 113 -4z m1209 -426
l0 -430 -110 0 -110 0 0 430 0 430 110 0 110 0 0 -430z m2701 426 c2 -2 2 -29
1 -60 l-4 -56 -109 0 -109 0 0 60 0 60 108 0 c60 0 111 -2 113 -4z m-994 -152
c47 -7 112 -70 123 -119 5 -22 10 -163 10 -312 l0 -273 -105 0 c-99 0 -105 1
-105 21 0 19 -1 19 -33 -5 -46 -35 -135 -37 -184 -3 -42 28 -53 66 -53 180 0
132 8 141 190 218 30 13 58 28 62 33 14 21 9 123 -7 136 -29 24 -45 -5 -45
-81 l0 -69 -101 0 -102 0 5 79 c10 173 112 230 345 195z m640 -10 c57 -36 58
-42 61 -381 l3 -313 -110 0 -111 0 0 279 c0 154 -3 286 -6 295 -8 22 -31 20
-44 -3 -6 -12 -10 -124 -10 -295 l0 -276 -110 0 -110 0 2 353 3 352 105 0
c101 0 104 -1 110 -23 l5 -24 24 21 c14 12 36 24 50 27 14 3 31 7 36 9 18 6
79 -7 102 -21z m-3567 6 c13 0 15 -44 13 -347 l-2 -348 -111 -3 -110 -3 0 350
c0 222 4 351 10 353 5 2 49 2 97 1 49 -1 95 -3 103 -3z m1510 -282 c5 -297 10
-327 44 -298 13 10 16 56 18 299 l3 286 105 0 105 0 3 -352 2 -353 -110 0
c-103 0 -110 1 -110 20 0 18 -2 18 -36 -5 -44 -30 -127 -34 -174 -10 -62 32
-65 49 -68 379 -2 164 -1 305 2 313 5 11 27 14 108 11 l103 -3 5 -287z m496
278 c2 -6 13 -87 24 -180 11 -93 21 -170 23 -172 1 -2 11 70 21 159 10 89 23
172 27 185 8 22 14 23 93 21 99 -2 89 16 107 -194 7 -74 15 -135 18 -135 4 0
14 69 25 153 10 83 22 157 27 163 11 13 175 19 182 7 3 -4 1 -35 -4 -68 -4
-33 -22 -179 -39 -325 -17 -146 -33 -275 -36 -287 -4 -22 -8 -23 -123 -23
l-118 0 -19 146 c-20 159 -24 159 -44 1 -6 -50 -13 -104 -16 -119 l-6 -28
-119 0 c-65 0 -119 1 -119 3 0 1 -20 157 -44 347 -25 189 -42 348 -40 352 8
13 175 8 180 -6z m1914 -346 l1 -345 -110 -3 -111 -3 0 350 0 350 103 3 c56 2
105 1 109 -2 4 -3 8 -161 8 -350z m-3570 3 l0 -78 -137 2 -138 2 -3 75 -3 76
141 0 140 0 0 -77z m3768 -645 l-3 -53 -2437 -3 -2438 -2 0 55 0 55 2441 0
2440 0 -3 -52z m-3870 -405 l-3 -88 -57 -1 c-32 0 -60 -2 -63 -5 -11 -12 -14
-70 -14 -366 l-1 -313 -110 0 -110 0 0 340 c0 265 -3 340 -12 341 -7 1 -35 2
-63 4 l-50 2 -3 87 -3 86 246 0 246 0 -3 -87z m772 -343 l0 -430 -110 0 -110
0 0 430 0 430 110 0 110 0 0 -430z m-429 271 c45 -14 96 -62 116 -109 9 -22
18 -82 21 -144 l5 -107 -139 -3 -139 -3 -3 -94 c-3 -97 5 -121 39 -121 22 0
28 21 31 110 l3 65 103 3 102 3 0 -63 c0 -91 -16 -137 -64 -184 -46 -47 -93
-64 -177 -64 -114 0 -192 40 -230 117 -22 45 -24 59 -24 238 1 157 4 199 19
243 20 59 60 96 128 118 47 15 149 13 209 -5z m828 4 c71 -18 126 -71 146
-139 8 -27 15 -89 15 -138 l0 -88 -140 0 -140 0 1 -82 c1 -109 8 -133 39 -133
21 0 26 6 32 40 4 22 7 61 7 88 l1 47 101 0 101 0 -3 -83 c-3 -71 -8 -90 -31
-124 -15 -23 -44 -54 -64 -70 -36 -27 -43 -28 -143 -28 -115 0 -150 11 -198
63 -51 55 -57 83 -57 282 -1 192 8 255 43 295 66 77 170 102 290 70z m564 -5
c90 -34 125 -84 134 -191 l6 -69 -100 0 -101 0 -4 65 c-4 70 -15 89 -42 79
-15 -6 -16 -28 -14 -227 3 -195 5 -221 20 -231 28 -17 40 6 46 88 l5 76 93 0
94 0 0 -67 c0 -80 -22 -133 -71 -179 -45 -41 -87 -54 -173 -54 -115 1 -169 28
-209 105 -19 36 -22 61 -25 230 -5 226 3 277 53 325 67 65 190 87 288 50z
m574 -16 c45 -23 64 -39 83 -74 24 -43 25 -54 28 -232 4 -227 -6 -276 -67
-331 -51 -46 -105 -60 -206 -55 -101 5 -158 34 -189 93 -19 36 -21 61 -24 235
-4 233 4 281 54 333 71 73 210 86 321 31z m580 24 c15 -6 40 -23 56 -37 l27
-25 28 25 c32 30 74 49 107 49 49 0 101 -29 123 -69 21 -37 22 -50 22 -345 l0
-306 -105 0 -105 0 0 278 c0 289 -4 321 -41 307 -24 -9 -29 -68 -29 -341 l0
-244 -105 0 -105 0 0 284 c0 240 -2 285 -15 296 -12 10 -18 10 -30 0 -13 -11
-15 -56 -15 -296 l0 -284 -105 0 -105 0 -1 33 c0 17 -1 175 -2 350 l-2 317
108 0 c103 0 107 -1 107 -22 0 -20 1 -20 19 -4 23 21 86 45 118 46 12 0 35 -5
50 -12z m-3279 -975 c3 -20 -1 -23 -27 -23 -33 0 -46 13 -37 36 9 25 60 14 64
-13z m362 -124 l0 -150 -27 3 -28 3 -3 134 c-1 74 0 141 2 148 3 7 17 13 31
13 l25 0 0 -151z m1170 126 c0 -21 -5 -25 -30 -25 -25 0 -30 4 -30 25 0 21 5
25 30 25 25 0 30 -4 30 -25z m480 -125 l0 -150 -30 0 -30 0 0 150 0 150 30 0
30 0 0 -150z m-2129 73 c25 -7 25 -39 -1 -46 -17 -4 -20 -14 -20 -66 0 -53 2
-61 19 -61 10 0 21 -6 24 -14 13 -34 -52 -49 -85 -19 -14 13 -18 31 -18 90 0
58 -3 73 -15 73 -8 0 -15 9 -15 19 0 10 7 21 15 25 8 3 15 12 15 20 0 9 12 22
28 30 26 14 27 14 30 -16 2 -20 10 -32 23 -35z m1769 27 c0 -23 4 -30 20 -30
13 0 20 -7 20 -20 0 -13 -7 -20 -20 -20 -19 0 -21 -5 -18 -67 2 -53 6 -68 18
-68 8 0 15 -9 15 -20 0 -15 -8 -21 -31 -23 -49 -5 -64 18 -64 99 0 53 -4 71
-15 75 -8 4 -15 14 -15 24 0 10 7 20 15 24 8 3 15 11 15 19 0 19 21 37 42 37
13 0 18 -8 18 -30z m-1428 -47 c11 -11 23 -37 28 -57 20 -88 -35 -152 -119
-141 -52 6 -76 25 -91 71 -19 57 10 126 60 147 28 12 102 0 122 -20z m916 10
c17 -15 21 -34 25 -115 4 -95 4 -98 -17 -98 -12 0 -26 4 -32 10 -6 6 -20 6
-41 -2 -60 -23 -116 27 -94 84 8 21 22 31 66 43 54 16 71 37 40 49 -18 6 -45
-2 -45 -15 0 -5 -13 -9 -30 -9 -38 0 -39 21 -4 49 34 27 103 28 132 4z m251 0
c18 -16 21 -30 22 -113 l2 -95 -29 0 -29 0 -3 82 c-2 67 -6 85 -20 92 -34 18
-47 -3 -52 -91 -5 -82 -5 -83 -32 -86 l-28 -3 0 110 0 111 25 0 c14 0 25 -5
25 -11 0 -5 10 -3 22 5 30 21 71 20 97 -1z m481 -10 c21 -21 30 -39 32 -68 l3
-40 -45 -2 c-25 -1 -57 -2 -72 -2 -20 -1 -26 -5 -22 -16 11 -28 44 -38 63 -21
22 20 71 21 71 3 0 -40 -76 -66 -133 -47 -29 9 -39 20 -53 55 -44 117 69 217
156 138z m331 10 c32 -19 57 -79 45 -108 -5 -13 -18 -16 -68 -14 -47 2 -63 -1
-63 -11 0 -23 32 -36 55 -22 27 17 70 16 70 -2 0 -8 -12 -24 -27 -36 -22 -18
-37 -21 -73 -17 -63 7 -90 40 -90 113 0 48 4 60 29 85 34 34 77 38 122 12z
m261 -13 c10 -12 18 -26 18 -31 0 -15 -57 -10 -70 6 -17 20 -26 19 -50 -5 -12
-12 -20 -33 -20 -55 0 -34 27 -75 50 -75 5 0 15 9 22 20 8 12 24 20 40 20 40
0 37 -34 -4 -61 -41 -25 -111 -20 -139 12 -48 55 -30 167 31 192 31 13 104 0
122 -23z m230 1 c30 -26 33 -35 33 -84 0 -65 -27 -103 -81 -113 -96 -18 -165
77 -123 170 27 58 120 73 171 27z m244 15 c17 -12 22 -12 39 0 25 19 70 18 89
-2 23 -22 27 -49 24 -134 -3 -74 -4 -75 -30 -78 l-28 -3 0 86 c0 70 -3 87 -16
92 -36 14 -49 -10 -52 -96 l-3 -82 -27 3 c-27 3 -27 4 -30 85 -3 94 -17 118
-51 84 -18 -18 -21 -34 -21 -97 l0 -75 -27 3 -28 3 -3 108 -3 107 46 -2 c25 0
48 2 52 5 11 12 48 8 69 -7z m478 -7 c14 -11 26 -27 26 -35 0 -19 -49 -18 -71
2 -14 12 -22 13 -38 4 -24 -12 -29 -45 -16 -106 7 -30 43 -38 58 -13 16 26 67
26 67 1 0 -34 -38 -57 -91 -57 -40 0 -54 5 -76 28 -24 23 -28 36 -28 83 0 50
3 58 33 85 40 36 96 39 136 8z m226 4 c14 -9 30 -24 37 -33 18 -22 16 -98 -4
-131 -33 -56 -127 -63 -174 -13 -32 34 -34 120 -4 154 38 42 103 52 145 23z
m275 6 c11 0 36 3 55 6 27 5 40 2 58 -13 20 -18 22 -28 22 -116 l0 -96 -30 0
-29 0 -3 88 c-3 82 -4 87 -25 90 -30 4 -43 -30 -43 -114 l0 -65 -27 3 c-28 3
-28 3 -31 91 -3 84 -3 87 -27 87 -33 0 -39 -14 -40 -100 l-2 -75 -29 0 -29 0
0 105 0 105 42 0 c23 1 46 4 50 9 4 4 21 5 38 1 16 -3 39 -6 50 -6z m473 -5
c20 -14 22 -23 22 -115 l0 -100 -27 3 c-28 3 -28 4 -33 88 -4 71 -8 85 -23 88
-36 7 -47 -16 -47 -99 l0 -79 -30 0 -30 0 0 110 0 110 25 0 c14 0 25 -5 25
-11 0 -5 10 -3 22 5 12 9 34 16 48 16 14 0 36 -7 48 -16z m245 6 l39 1 -4
-131 c-3 -124 -4 -133 -27 -151 -17 -14 -39 -19 -79 -19 -60 0 -92 18 -92 52
0 23 42 25 64 3 29 -29 76 -12 76 28 0 14 -5 17 -17 12 -53 -20 -72 -17 -103
13 -26 27 -30 37 -30 87 0 50 4 60 30 87 27 26 36 29 68 24 20 -3 54 -6 75 -6z
m-5849 -61 c10 -33 20 -58 23 -56 3 3 10 29 17 59 10 49 14 53 43 56 30 3 31
1 43 -50 18 -77 22 -78 40 -13 l17 60 60 3 c52 2 62 0 67 -15 2 -10 10 -38 17
-63 13 -50 18 -45 39 38 10 37 14 42 40 42 25 0 30 -5 39 -37 6 -21 11 -46 11
-55 0 -39 17 -23 34 33 l17 59 59 0 c64 0 65 -1 86 -100 7 -34 7 -34 15 -10 4
14 13 44 19 68 10 37 14 42 40 42 19 0 30 -6 34 -17 3 -10 10 -38 17 -63 13
-50 18 -45 39 38 10 38 14 42 41 42 27 0 30 -3 23 -22 -4 -13 -19 -61 -33
-108 -26 -82 -28 -85 -58 -88 -31 -3 -32 -2 -44 58 -6 33 -14 58 -18 55 -3 -3
-12 -29 -21 -58 -14 -47 -18 -52 -45 -52 -29 0 -30 3 -61 105 -22 73 -34 100
-39 87 -4 -10 -19 -57 -34 -105 -26 -83 -28 -87 -56 -87 -25 0 -30 5 -42 45
-8 25 -17 52 -20 60 -3 8 -13 -12 -22 -45 -15 -56 -18 -60 -46 -60 -29 0 -30
3 -59 98 -17 53 -33 97 -37 97 -4 0 -21 -45 -39 -100 -30 -97 -32 -100 -60
-100 -30 0 -32 4 -45 70 -12 58 -18 57 -35 -5 -15 -55 -19 -60 -45 -60 -27 0
-30 5 -55 80 -45 134 -45 135 -12 135 27 0 31 -4 46 -61z m1214 -46 l-3 -108
-30 0 -30 0 -3 94 c-1 52 -1 100 2 108 3 7 19 13 36 13 l31 0 -3 -107z m476
24 c3 -86 16 -106 51 -83 17 11 20 26 23 90 l3 76 29 0 30 0 0 -109 0 -109
-80 2 c-107 2 -114 9 -119 127 l-3 90 31 -3 32 -3 3 -78z m236 41 c6 -24 14
-52 17 -63 l6 -20 8 20 c5 11 13 39 19 63 10 37 14 42 40 42 25 0 30 -5 39
-37 6 -21 11 -46 11 -55 0 -40 18 -23 34 32 15 56 18 60 46 60 17 0 30 -1 30
-2 0 -2 -15 -50 -33 -108 -30 -96 -36 -105 -59 -108 -31 -4 -44 15 -54 77 -8
53 -20 52 -29 -1 -11 -63 -22 -80 -53 -76 -23 3 -29 12 -59 108 -18 58 -33
106 -33 108 0 1 13 2 30 2 26 0 30 -5 40 -42z m820 -68 l0 -110 -30 0 -30 0 0
110 0 110 30 0 30 0 0 -110z m-1790 -80 c0 -27 -3 -30 -30 -30 -27 0 -30 3
-30 30 0 27 3 30 30 30 27 0 30 -3 30 -30z m3460 -1 c0 -27 -3 -30 -27 -27
-22 2 -29 9 -31 31 -3 24 0 27 27 27 28 0 31 -3 31 -31z m963 19 c8 -26 -5
-48 -29 -48 -26 0 -40 20 -30 45 7 18 53 20 59 3z m-2304 -319 c37 -13 51 -35
51 -83 0 -36 0 -36 -45 -36 -33 0 -45 4 -46 15 -1 8 -2 20 -3 27 0 6 -7 13
-13 16 -9 2 -13 -7 -13 -27 0 -27 8 -36 55 -67 62 -39 75 -62 75 -132 0 -58
-19 -87 -65 -102 -84 -28 -165 21 -165 101 l0 39 50 0 50 0 0 -40 c0 -29 4
-40 15 -40 11 0 15 11 15 38 0 34 -5 40 -54 71 -30 18 -59 45 -65 58 -14 30
-14 98 -1 123 23 44 95 61 159 39z m-1513 -32 c30 -35 33 -98 6 -124 -17 -17
-17 -19 3 -35 17 -13 20 -28 20 -88 0 -99 -13 -110 -141 -110 l-94 0 0 196 0
196 91 -4 c85 -3 92 -5 115 -31z m1224 -162 l0 -195 -50 0 -50 0 0 195 0 195
50 0 50 0 0 -195z m-808 143 c2 -15 10 -23 23 -23 13 0 21 8 23 23 3 19 9 22
53 22 43 0 49 -3 49 -20 0 -11 7 -23 15 -26 8 -4 15 -15 15 -25 0 -11 -7 -22
-15 -25 -12 -5 -15 -26 -15 -105 0 -81 3 -99 15 -99 10 0 15 -10 15 -30 0 -29
-1 -30 -47 -30 -76 0 -83 13 -83 146 0 81 -3 114 -12 117 -32 11 -38 -5 -38
-104 0 -81 3 -99 15 -99 10 0 15 -10 15 -30 0 -29 -1 -30 -49 -30 -72 0 -81
17 -81 152 0 60 -4 110 -10 113 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 11
10 25 0 23 3 25 49 25 44 0 50 -3 53 -22z m2098 3 c0 -10 7 -24 15 -31 8 -7
15 -19 15 -27 0 -7 -7 -16 -15 -19 -12 -5 -15 -26 -15 -105 0 -81 3 -99 15
-99 10 0 15 -10 15 -30 0 -29 -1 -30 -47 -30 -76 0 -83 13 -83 147 0 69 -4
113 -10 113 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 11 10 25 0 23 3 25 50
25 42 0 50 -3 50 -19z m-2280 -36 c30 -15 50 -62 50 -117 0 -26 -2 -27 -62
-30 l-63 -3 -3 -47 c-2 -37 0 -48 12 -48 10 0 16 12 18 38 3 35 5 37 42 40 49
4 60 -7 52 -53 -15 -76 -92 -107 -172 -69 -39 18 -54 58 -54 139 0 92 10 125
44 146 37 23 96 25 136 4z m537 3 c42 -19 52 -38 53 -100 l0 -56 -57 1 -58 2
-3 -47 c-2 -38 0 -48 12 -48 12 0 16 10 16 40 l0 40 45 0 45 0 0 -43 c0 -34
-6 -49 -28 -70 -48 -46 -150 -32 -181 26 -9 17 -12 54 -10 122 4 92 5 98 32
121 32 27 88 32 134 12z m183 -5 c0 -15 1 -15 18 0 32 30 42 21 42 -38 0 -40
-4 -55 -14 -55 -33 0 -44 -48 -46 -193 0 -15 -7 -18 -45 -15 -25 2 -45 6 -45
11 0 4 0 75 0 157 l0 150 45 0 c35 0 45 -4 45 -17z m745 7 c4 -7 12 -7 25 0
27 15 66 12 80 -5 11 -13 15 -13 33 0 28 20 66 19 89 -2 16 -14 18 -33 18
-160 l0 -144 -47 3 -47 3 2 89 c4 96 -5 166 -19 166 -5 0 -9 -58 -9 -130 l0
-130 -50 0 -50 0 0 131 c0 100 -3 130 -12 127 -9 -3 -14 -41 -16 -131 l-3
-127 -44 0 -45 0 0 160 0 160 44 0 c25 0 48 -5 51 -10z m448 -1 c39 -18 47
-50 47 -184 l0 -124 -91 0 c-112 1 -129 13 -129 89 0 55 6 63 70 94 35 16 45
26 45 45 0 38 -23 37 -27 -1 -3 -31 -5 -33 -43 -33 -34 0 -40 3 -43 23 -5 33
15 78 41 90 29 14 100 15 130 1z m180 -1 c3 -10 7 -10 20 0 24 21 37 6 37 -43
0 -39 -4 -46 -30 -60 l-30 -16 0 -94 0 -95 -50 0 -50 0 0 160 0 160 49 0 c31
0 51 -5 54 -12z m424 -22 c17 -20 23 -39 23 -76 l0 -49 -57 -3 -58 -3 -3 -47
c-2 -38 0 -48 12 -48 12 0 16 10 16 40 l0 40 45 0 45 0 0 -42 c-1 -46 -12 -68
-47 -86 -56 -30 -156 1 -169 52 -3 14 -4 66 -3 117 4 86 6 92 33 116 26 22 37
25 85 21 44 -4 59 -10 78 -32z m156 22 c4 -10 7 -10 18 0 28 26 39 14 39 -42
0 -49 -2 -54 -25 -60 -19 -5 -25 -13 -26 -34 0 -15 -1 -58 -2 -97 l-2 -70 -50
0 -50 0 -3 145 c-1 79 0 150 2 157 7 17 93 18 99 1z"/>
<path d="M4311 6666 c-16 -19 -25 -271 -16 -439 6 -111 10 -140 23 -144 37
-15 42 20 42 298 0 230 -2 270 -16 283 -19 19 -19 19 -33 2z"/>
<path d="M6470 6246 c-34 -37 -42 -142 -12 -169 33 -30 43 -3 40 103 l-3 92
-25 -26z"/>
<path d="M4125 5060 c-4 -6 -9 -35 -12 -65 l-6 -55 32 0 c31 0 31 1 31 49 0
27 -3 56 -6 65 -7 18 -30 21 -39 6z"/>
<path d="M4975 5060 c-4 -6 -9 -35 -12 -65 l-6 -55 32 0 31 0 0 59 c0 44 -4
60 -16 65 -21 8 -22 8 -29 -4z"/>
<path d="M6072 5062 c-9 -7 -12 -60 -10 -233 3 -216 4 -224 23 -224 19 0 20 8
23 210 3 250 0 270 -36 247z"/>
<path d="M3642 4114 c-19 -13 -23 -23 -20 -62 2 -38 8 -49 28 -59 22 -12 27
-10 48 13 28 31 28 64 1 98 -24 31 -27 31 -57 10z"/>
<path d="M4578 4028 c-33 -11 -35 -23 -8 -38 16 -8 24 -6 40 10 11 11 18 25
15 30 -7 12 -11 12 -47 -2z"/>
<path d="M5307 4118 c-32 -18 -20 -38 23 -38 37 0 40 2 31 19 -13 24 -33 31
-54 19z"/>
<path d="M5646 4114 c-25 -25 -19 -34 24 -34 44 0 49 8 22 33 -23 21 -27 21
-46 1z"/>
<path d="M6100 4101 c-35 -66 20 -145 69 -100 41 39 22 119 -29 119 -19 0 -33
-7 -40 -19z"/>
<path d="M7088 4123 c-9 -2 -22 -18 -29 -34 -15 -37 -4 -75 28 -96 19 -13 24
-12 42 6 22 22 28 70 11 100 -11 21 -28 29 -52 24z"/>
<path d="M8051 4101 c-26 -48 5 -130 43 -115 24 9 40 61 30 97 -12 40 -56 51
-73 18z"/>
<path d="M3760 3565 c0 -24 5 -35 15 -35 10 0 15 11 15 35 0 24 -5 35 -15 35
-10 0 -15 -11 -15 -35z"/>
<path d="M3760 3400 c0 -38 4 -50 15 -50 11 0 15 12 15 50 0 38 -4 50 -15 50
-11 0 -15 -12 -15 -50z"/>
<path d="M4024 3526 c-4 -10 -2 -22 4 -28 8 -8 13 -7 18 6 4 10 2 22 -4 28 -8
8 -13 7 -18 -6z"/>
<path d="M4570 3521 c0 -11 5 -23 10 -26 6 -4 10 5 10 19 0 14 -4 26 -10 26
-5 0 -10 -9 -10 -19z"/>
<path d="M5936 3404 c-11 -29 -7 -64 9 -64 11 0 15 11 15 40 0 40 -13 53 -24
24z"/>
<path d="M6520 3515 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
